export default {
  auth: {
    login: 'Login',
    register: 'Registrieren',
    register_success: 'Erfolgreich registriert!',
    register_success_message: 'Wir haben Ihnen eine E-Mail gesendet. Hinterlegen Sie ein Passwort und loggen Sie sich ein.',
    forgot: 'Passwort vergessen?',
    login_as_guest: 'Zurück',
    reset_password: 'Passwort zurücksetzen',
    authenticate_error_message: 'Es ist leider ein Fehler aufgetreten. Überprüfen Sie die Eingabefelder.',
    reset_password_message: 'Ihr Passwort wurde erfolgreich zurückgesetzt. Wir haben Ihnen eine E-Mail gesendet. Klicken Sie auf den Link in der E-Mail und vergeben Sie ein neues Passwort.',
    forgot_subject: 'Setzen Sie ihr neues Passwort',
    set_password: 'Passwort setzen',
    set_password_confirm_failed: 'Die beiden Passwörter sind nicht identisch.',
    set_password_success_message: 'Das Passwort wurde erfolgreich geändert.',
    invalid_request: 'Das Passwort kann nicht zurückgesetzt werden, bitte fordern Sie ein neues Passwort an.',
    logout: 'Abmelden',
  },
  passwordemail: {
    subject: 'Passwort zurücksetzen',
    title: 'Neues Passwort',
    subtitle: 'Mit dem Klick auf den Button können Sie dass Passwort neu vergeben',
    buttontitle: 'Jetzt Passwort vergeben'
  },
  generic: {
    from: 'Von',
    until: 'Bis',
    role: 'Rolle',
    title: 'Titel',
    email: 'E-Mail',
    search: 'Suche',
    firstname: 'Vorname',
    lastname: 'Nachname',
    company: 'Unternehmen',
    username: 'Benutzername',
    password: 'Passwort',
    cancel: 'Abbrechen',
    password_confirm: 'Passwort bestätigen',
    message: 'Nachricht',
    upload: 'Hochladen',
    download: 'Herunterladen',
    new_folder: 'Neuer Ordner',
    edit_folder: 'Kategorie(n) bearbeiten',
    download_original: 'Original herunterladen',
    download_preview: 'Vorschau herunterladen',
    create: 'Erstellen',
    sort: 'Sortieren',
    sort_at: 'Sortieren nach',
    apply: 'Anwenden',
    delete: 'Löschen',
    edit: 'Bearbeiten',
    copy: 'Kopieren',
    move: 'Ausschneiden',
    paste: 'Einfügen',
    select_all: 'Alle selektieren',
    deselect_all: 'Alle deselektieren',
    save: 'Speichern',
    select: 'Auswählen',
    menu: 'Menü',
  },
  category: {
    current_users: 'Aktuelle Benutzer'
  },
  meeting: {
    invite: 'Einladen',
    end_meeting: 'Meeting beenden',
    invite_users: 'Benutzer einladen',
    invite_guests: 'Gäste einladen (Email)',
    invite_successfull: 'Die Einladung wurde erfolgreich versendet.',
    end_meeting_message: 'Sie beenden das Meeting. Beachten Sie, dass alle Teilnehmer mit der Bestätigung ebenfalls das Meeting verlassen. Möchten Sie das Meeting für alle Teilnehmer beenden?',
  },
  meetingemail: {
    title: 'Einladung zum Meeting',
    subtitle: 'Wie vereinbart',
    subtitlewithdate: 'Das Meeting wurde am xxxfromxxx Uhr bis xxxuntilxxx Uhr festgelegt',
    buttontitle: 'Jetzt Meeting beitreten'
  },
  clientSelector: {
    no_projects_found: 'Es wurden keine Projekte gefunden',
    projects: 'Projekte'
  },
  shared: {
    share: 'Share',
    shareurl: 'Share Adresse',
    copyclipboard: 'In Zwischenablage kopieren',
    copied: 'wurde erfolgreich kopiert',
    errorcopy: 'Fehler beim Schreiben Zwischenablage',
    create: 'Share neu anlegen',
    created: 'Share wurde neu angelegt',
    delete: 'Share Löschen',
    deleted: 'Share wurde gelöscht',
    delete_text: 'Möchten Sie wirklich den Share löschen?'
  },
  folders: {
    sortDirection: 'Reihenfolge',
    sortDirection_asc: 'aufsteigend',
    sortDirection_desc: 'absteigend',
    uploading: 'Bitte einen Moment Geduld bis die Datei(en) hochgeladen wurden...',
    deleting: 'Bitte einen Moment Geduld bis die Datei(en) gelöscht wurden...',
    delete_category_text: 'Möchten Sie wirklich die Kategorie löschen?',
    sort: 'Sortierung',
    sortSaved: 'neue Reihenfolge gespeichert',
    sortTypes: {
      default: 'Standard-Sortierung',
      date: 'Datum',
      name: 'Name',
      size: 'Größe',
      attribute: 'Attribut',
    }
  }
}